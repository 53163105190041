import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
} from "@chakra-ui/react";
import axios from "axios";
import FileSaver from "file-saver";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import { ReadMore } from "@equidefi/portals/components/ReadMore";
import InvestmentProgress from "@equidefi/portals/components/investments/InvestmentProgress";
import InvestmentSignatureList from "@equidefi/portals/components/investments/InvestmentSigantureList";
import { useInvestmentSignatures } from "@equidefi/portals/hooks/useAgreements";
import { offeringIsCompleted } from "@equidefi/shared";
import { Heading, Text } from "@equidefi/ui";

import InvestmentCard from "../../../../components/investment/InvestmentCard";
import { ReinvestBox } from "../../../../components/investment/ReinvestBox";
import { OfferingTerminated } from "../../../../components/offerings/OfferingTerminated";
import { REINVEST_STATUSES } from "../../../../constants/investment";
import { useInvestment } from "../../../../hooks/useInvestments";
import { useIssuer } from "../../../../hooks/useIssuers";
import { useOffering } from "../../../../hooks/useOfferings";
import InvestmentDisplayItem from "./InvestmentDisplayItem";
import InvestmentNdaCopy from "./InvestmentNdaCopy";
import OfferingAgreementsList from "./OfferingAgreementsList";
import OfferingInfoCard from "./OfferingInfoCard";
import OfferingInvestorPackageList from "./OfferingInvestorPackageList";
import PaymentsCard from "./PaymentsCard";

const AccordionSection = ({ title, children, hidden }) => {
  return (
    <AccordionItem mt="4" hidden={hidden} maxWidth="full">
      <Box borderRadius="lg" py="2">
        <AccordionButton>
          <Heading textStyle="h3" fontWeight="bold" m="0">
            {title}
          </Heading>
          <AccordionIcon ml="auto" />
        </AccordionButton>
        <AccordionPanel>{children}</AccordionPanel>
      </Box>
    </AccordionItem>
  );
};

const InvestmentOverview = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const ndaRef = useRef(null);

  const { data: investment, isFetched } = useInvestment(id, {
    refetchOnMount: true,
  });
  const { data: offering } = useOffering(investment?.offering_id);
  const { data: issuer } = useIssuer(investment?.issuer_id);

  const isOfferingCompleted = offeringIsCompleted(offering);

  const handlePrintNda = useReactToPrint({
    content: () => ndaRef.current,
    documentTitle: `${investment?.investor.name} - NDA.pdf`,
  });

  const [spinner, showSpinner] = useState(false);

  const { data } = useInvestmentSignatures(investment?.id);

  const agreement = data?.investment_agreement;
  const signatures = data?.signatures;

  const investors = useMemo(
    () =>
      signatures?.filter((s) =>
        s.role ? s.role !== "countersigner" : s.order !== null
      ) ?? [],
    [signatures]
  );

  useEffect(() => {
    dispatch({ type: "offering", payload: offering });
  }, [dispatch, investment, offering]);

  const downloadAgreement = (agreement) => {
    showSpinner(true);
    axios
      .get(`investment/${investment?.id}/agreement/${agreement.envelope_id}`)
      .then((response) => {
        let toBytes = new Uint8Array(response.data.data),
          toBinary = ``;
        toBytes.forEach((res) => (toBinary += String.fromCharCode(res)));

        const file = window.btoa(toBinary);
        FileSaver.saveAs(
          `data:application/pdf;base64,${file}`,
          agreement.name.replace(/[^a-zA-Z ]/g, "")
        );

        showSpinner(false);
      });
  };

  const reverse = (item) => {
    return [...item].reverse();
  };

  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      w="full"
      p="4"
      align="start"
      gap="4"
    >
      <Box p="0" w="full">
        <InvestmentCard
          investment={investment}
          issuer={issuer}
          offering={offering}
        />
        {isFetched && (
          <InvestmentProgress investment={investment} offering={offering} />
        )}

        {isOfferingCompleted && <OfferingTerminated />}
        <Accordion
          variant="card"
          defaultIndex={[0]}
          allowMultiple
          maxWidth={"full"}
        >
          <AccordionSection title="Investment Details">
            <InvestmentDisplayItem
              label="Amount Invested"
              value={
                <NumberFormat
                  thousandSeparator
                  displayType="text"
                  fixedDecimalScale={2}
                  decimalScale={2}
                  prefix="$"
                  value={investment?.amount}
                />
              }
            />
            <InvestmentDisplayItem
              label="Price per Share"
              value={
                <NumberFormat
                  thousandSeparator
                  displayType="text"
                  fixedDecimalScale={2}
                  decimalScale={2}
                  prefix="$"
                  value={investment?.price}
                />
              }
            />{" "}
            <InvestmentDisplayItem
              label="Number of Shares"
              value={
                <NumberFormat
                  thousandSeparator
                  displayType="text"
                  value={investment?.quantity}
                />
              }
            />
            <InvestmentDisplayItem
              label="Investment Date"
              value={moment(investment?.create_date).format("MM/DD/yyyy")}
            />
          </AccordionSection>
          <AccordionSection
            title="Investment Signers"
            hidden={!investors.length}
          >
            <InvestmentSignatureList
              investmentId={agreement?.investment_id}
              investors={investors}
            />
          </AccordionSection>
          <AccordionSection title="Company Business Description">
            <InvestmentDisplayItem
              value={<ReadMore text={offering?.description} maxLength={500} />}
            />
            <InvestmentDisplayItem label="Issuer CIK" value={issuer?.cik} />
            <InvestmentDisplayItem
              label="Issuer EIN"
              value={
                issuer?.ein
                  ? `${issuer?.ein.substring(0, 2)}-${issuer?.ein.substring(3)}`
                  : "N/A"
              }
            />
            <InvestmentDisplayItem label="Issuer SIC" value={issuer?.sic} />
            <InvestmentDisplayItem
              label="Doing Business As"
              value={issuer?.doing_business_as}
            />
            {issuer?.address.line_1 && (
              <InvestmentDisplayItem
                label="Mailing Address"
                value={
                  <>
                    {issuer?.address.line_1}
                    <br />
                    {issuer?.address.line_2 ? (
                      <>
                        {issuer?.address.line_2}
                        <br />
                      </>
                    ) : null}
                    {issuer?.address.city}, {issuer?.address.state}{" "}
                    {issuer?.address.postal_code}
                  </>
                }
              />
            )}
            {issuer?.business_address.line_1 && (
              <InvestmentDisplayItem
                label="Business Address"
                value={
                  <>
                    {issuer?.business_address.line_1}
                    <br />
                    {issuer?.business_address.line_2 ? (
                      <>
                        {issuer?.business_address.line_2}
                        <br />
                      </>
                    ) : null}
                    {issuer?.business_address.city},{" "}
                    {issuer?.business_address.state}{" "}
                    {issuer?.business_address.postal_code}
                  </>
                }
              />
            )}
          </AccordionSection>

          <AccordionSection
            title="Payment Details"
            hidden={
              !investment?.payments?.length &&
              !investment?.stripe_payments?.length
            }
          >
            <PaymentsCard
              payments={reverse(investment?.payments ?? [])}
              stripePayments={reverse(investment?.stripe_payments ?? [])}
            />
          </AccordionSection>

          <AccordionSection title="Agreements">
            <OfferingAgreementsList
              offering={offering}
              data={data}
              investment={investment}
              isLoading={spinner}
              onPrintNda={handlePrintNda}
              onDownloadAgreement={(agreement) => downloadAgreement(agreement)}
            />
          </AccordionSection>

          <AccordionSection
            title="Investor Package Download"
            hidden={!offering?.documents?.length}
          >
            <OfferingInvestorPackageList
              documents={offering?.documents ?? []}
            />
          </AccordionSection>

          <AccordionSection title="Offering Details">
            <OfferingInfoCard offering={offering} />
          </AccordionSection>
        </Accordion>

        <ReinvestBox
          offeringId={offering?.id}
          disabled={!REINVEST_STATUSES.includes(investment?.status)}
        />

        <Box hidden p="3">
          <Box ref={ndaRef} p="6">
            <Heading>Non-Disclosure Agreement</Heading>
            <InvestmentNdaCopy
              name={offering?.issuer?.name}
              state={issuer?.address?.state}
            />
            <hr />
            <Text>Name: {investment?.investor?.name}</Text>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default InvestmentOverview;
