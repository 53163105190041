import { Table, TD, TH, TR } from "@ag-media/react-pdf-table";
import { StyleSheet, Text } from "@react-pdf/renderer";
import React from "react";

import {
  PDFDocument,
  PDFPage,
  PDFSection,
} from "@equidefi/portals/components/PDF";
import { styles } from "@equidefi/portals/components/PDF.styles";

import { USCurrencyFormatter } from "../../../../constants/format";
import { PaymentReceiptStyle } from "./PaymentReceiptStyle";

const receiptStyles = StyleSheet.create(PaymentReceiptStyle);

const PaymentReceiptPDF = ({ investment, offering }) => {
  const transactionsSet = new Set();
  const stripePayments = investment.stripe_payments;
  const settingKeys = offering.settings.reduce(
    (memo, setting) => ({
      ...memo,
      [setting.id]: setting.value,
    }),
    {}
  );

  const recipientBank = settingKeys.payment_bank_name;
  const accountNumber = settingKeys.payment_account_number;

  const manualPayments = (investment.payments ?? []).filter(
    (p) => p.status === "manual_payment_succeeded"
  );
  const manualRefunds = (investment.payments ?? []).filter(
    (p) => p.status === "manual_refund_succeeded"
  );

  for (const payment of manualPayments) {
    transactionsSet.add({
      type: "Manual Payment",
      amount: USCurrencyFormatter.format(payment.amount),
      date: new Date(payment.create_date)
        .toDateString()
        .split(" ")
        .slice(1)
        .join(" "),
      recipientBank,
      accountNumber,
    });
  }

  for (const payment of manualRefunds) {
    transactionsSet.add({
      type: "Manual Refund",
      amount: USCurrencyFormatter.format(payment.amount),
      date: new Date(payment.create_date)
        .toDateString()
        .split(" ")
        .slice(1)
        .join(" "),
      recipientBank,
      accountNumber,
    });
  }

  for (const payment of stripePayments) {
    transactionsSet.add({
      type: "Stripe Payment",
      amount: USCurrencyFormatter.format(payment.amount),
      date: new Date(payment.create_date)
        .toDateString()
        .split(" ")
        .slice(1)
        .join(" "),
      recipientBank,
      accountNumber,
    });
  }

  const transactions = Array.from(transactionsSet);

  return (
    <PDFDocument>
      <PDFPage>
        <PDFSection>
          <Text style={receiptStyles.title}>Payment Receipt</Text>
          <Text style={receiptStyles.text}>
            Created on {new Date().toLocaleDateString()}
          </Text>
        </PDFSection>
        <PDFSection>
          <Table style={styles.table}>
            <TH>
              <TD style={[styles.tableCell, styles.tableHeader]}>Amount</TD>
              <TD style={[styles.tableCell, styles.tableHeader]}>Date</TD>
              <TD style={[styles.tableCell, styles.tableHeader]}>
                Recipient Bank
              </TD>
              <TD style={[styles.tableCell, styles.tableHeader]}>
                Account Number
              </TD>
              <TD style={[styles.tableCell, styles.tableHeader]}>
                Payment Mode
              </TD>
            </TH>
            {transactions.map((transaction, index) => (
              <TR key={index}>
                <TD style={styles.tableCell}>{transaction.amount}</TD>
                <TD style={styles.tableCell}>{transaction.date}</TD>
                <TD style={styles.tableCell}>{transaction.recipientBank}</TD>
                <TD style={styles.tableCell}>{transaction.accountNumber}</TD>
                <TD style={styles.tableCell}>{transaction.type}</TD>
              </TR>
            ))}
          </Table>
        </PDFSection>
      </PDFPage>
    </PDFDocument>
  );

  return null;
};

export default PaymentReceiptPDF;
