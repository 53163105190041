import React, { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { Center, ListItem, Spinner, UnorderedList } from "@chakra-ui/react";
import InquiryClient from "@equidefi/portals/clients/InquiryClient";
import { useApi } from "@equidefi/portals/hooks/useApi";
import { Text } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";

import { InvestmentStatusView } from "../../../components/offerings/StatusView";
import { WorkflowButton } from "../../../components/workflow/WorkflowButton";
import { CONFIG } from "../../../constants/config";
import { useInquiry, useResumeInquiry } from "../../../hooks/useInquiries";
import useWorkflowPosition from "../../../hooks/useWorkflowPosition";
import { WorkflowContainer } from "../WorkflowContainer";
import { WorkflowContext } from "../context";

const buildInquiryRedirectUri = (slug, investmentId) => {
  const url = new URL(
    `/offerings/${slug}/${investmentId}/kyc`,
    new URL(CONFIG.URL)
  );
  return url.toString();
};

const InquiryStatusView = ({ inquiryStatus, isLoading, kyckyb }) => {
  const { investment, offering } = useContext(WorkflowContext);
  const resumeInquiry = useResumeInquiry();
  const inquiryApi = useApi(InquiryClient);

  if (!inquiryStatus || !investment) {
    return null;
  }

  const onStart = async () => {
    try {
      const data = await inquiryApi.start({
        offering_id: offering.id,
        investment_id: investment.id,
        redirect_uri: buildInquiryRedirectUri(offering.slug, investment.id),
      });

      window.location.href = data.data.link;
    } catch (error) {
      console.error(error);
      toast({
        status: "error",
        description:
          error.response?.data?.errors?.join(". ") ?? "Something went wrong",
      });
    }
  };

  const onResume = async () => {
    try {
      const response = await resumeInquiry.mutateAsync({
        inquiryId: investment.persona_inquiry_id,
      });
      window.location.href = response.link;
    } catch (error) {
      // When inquiry is approved, but the webhook has not been processed
      // So we need to update the inquiry status to approved in this case.
      // Update inquery status based on the error, is it doable?
      toast.error("Sorry, something went wrong.");
    }
  };

  if (inquiryStatus === "unknown" || !investment.persona_inquiry_id) {
    return (
      <>
        <InvestmentStatusView title="Verify your identity">
          <Text mt="2.5">Now it’s time to Verify Your Identity!</Text>
          <UnorderedList p="0" mt="4">
            <ListItem mt>
              Please keep your driver’s license or government-issued ID (e.g.,
              passport) handy.
            </ListItem>
            <ListItem>
              Your front-facing camera will be used to validate your ID.
            </ListItem>
            <ListItem>
              If you don’t have a camera on this device, you can complete the
              verification on your mobile device.
            </ListItem>
          </UnorderedList>
        </InvestmentStatusView>
        <WorkflowButton
          isLoading={inquiryApi.isLoading}
          as={Link}
          onClick={onStart}
        >
          Start
        </WorkflowButton>
      </>
    );
  }

  if (inquiryStatus === "approved") {
    return (
      <>
        <InvestmentStatusView title="Approved!" icon={Icon.CheckCircle}>
          Well done! It looks like your {kyckyb} inquiry was approved. You can
          continue to proceed with our investment.
        </InvestmentStatusView>
        <WorkflowButton
          isLoading={isLoading}
          as={Link}
          to={`/offerings/${offering.slug}/${investment.id}/agreement`}
        >
          Continue
        </WorkflowButton>
      </>
    );
  }

  if (inquiryStatus === "completed" && kyckyb === "KYC") {
    return (
      <>
        <InvestmentStatusView title="Processing...">
          Your inquiry has been completed, but is still processing. Please
          standby...
        </InvestmentStatusView>
        <Center>
          <Spinner />
        </Center>
      </>
    );
  }

  if (
    inquiryStatus === "created" ||
    inquiryStatus === "completed" ||
    inquiryStatus === "pending"
  ) {
    if (kyckyb === "KYB")
      return (
        <>
          <InvestmentStatusView title="KYB Started...">
            You have been sent an email with a request to complete your
            individual KYC. Your business inquiry has been started, and you will
            be notified by email once your KYB has been reviewed.
          </InvestmentStatusView>
          {inquiryStatus === "created" && (
            <WorkflowButton
              isLoading={resumeInquiry.isLoading}
              onClick={onResume}
            >
              Resume
            </WorkflowButton>
          )}
        </>
      );
    if (kyckyb === "KYC")
      return (
        <>
          <InvestmentStatusView title="KYC Started...">
            Your inquiry has been started but has not been completed yet, please
            click the link below to resume.
          </InvestmentStatusView>
          <WorkflowButton
            isLoading={resumeInquiry.isLoading}
            onClick={onResume}
          >
            Resume
          </WorkflowButton>
        </>
      );
  }

  if (
    inquiryStatus === "expired" ||
    inquiryStatus === "unknown" ||
    inquiryStatus === "resumed"
  ) {
    return (
      <>
        <InvestmentStatusView title="KYC Expired" centered muted>
          Your inquiry has expired, please click the link below to resume.
        </InvestmentStatusView>
        <WorkflowButton isLoading={resumeInquiry.isLoading} onClick={onResume}>
          Resume
        </WorkflowButton>
      </>
    );
  }

  if (inquiryStatus === "declined") {
    return (
      <InvestmentStatusView title="Sorry!" icon={Icon.ShieldOff}>
        It looks like your {kyckyb} inquiry was declined. If you believe this to
        be in error, please contact the issuer of the offering.
      </InvestmentStatusView>
    );
  }

  if (inquiryStatus === "needs_review") {
    return (
      <InvestmentStatusView title="Under Review" icon={Icon.Activity}>
        It looks like your {kyckyb} verification was automatically marked for
        review. The issuer will review your information. You will receive an
        email when the issuer has accepted or declined your information.
      </InvestmentStatusView>
    );
  }
  return null;
};

export const WorkflowKYC = () => {
  const { investment } = useContext(WorkflowContext);
  useWorkflowPosition(6);

  const { data: inquiry, isLoading } = useInquiry(
    investment?.persona_inquiry_id,
    {
      refetchInterval: 15000,
      refetchIntervalInBackground: true,
    }
  );

  const inquiryStatus = useMemo(
    () => inquiry?.status ?? "unknown",
    [inquiry?.status]
  );
  const kyckyb = investment?.entity_type === "ENTITY" ? "KYB" : "KYC";

  return (
    <WorkflowContainer>
      <InquiryStatusView
        inquiryStatus={inquiryStatus}
        investment={investment}
        isLoading={isLoading}
        kyckyb={kyckyb}
      />
    </WorkflowContainer>
  );
};
