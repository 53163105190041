import { useMutation, useQuery } from "@tanstack/react-query";

import InvestmentClient from "@equidefi/portals/clients/InvestmentClient";
import UserToken from "@equidefi/portals/clients/UserToken";
import { useApi } from "@equidefi/portals/hooks/useApi";
import InvestorInvestmentClient from "../clients/InvestmentClient";
import StripeClient from "../clients/StripeClient";

import { validate } from "uuid";

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useInvestment = (id, options = {}) => {
  const investmentApi = useApi(InvestmentClient);

  const validUuid = validate(id);

  return useQuery(["investment", id], () => investmentApi.get(id), {
    enabled: validUuid,
    ...options,
  });
};

export const useUserInvestments = (params, options = {}) => {
  const investmentApi = useApi(InvestmentClient);

  return useQuery(
    ["userInvestments", params],
    () => investmentApi.all(params),
    {
      enabled: !!UserToken.token(),
      ...options,
    }
  );
};

/**
 * @param {string} id
 * @param {"accreditation" | "investor-info"} stage
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useInvestmentUpdateStage = (id, stage, options = {}) => {
  return useMutation(
    ({ data } = {}) => InvestorInvestmentClient.updateStage(id, stage, data),
    options
  );
};

/**
 * @param {string} investmentId
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useInitiatePaymentMutation = (investmentId, options = {}) => {
  return useMutation(
    () => StripeClient.createPaymentIntent(investmentId),
    options
  );
};

export const useResetInquiry = () => {
  return useMutation(({ investmentId, inquiryId }) =>
    InvestorInvestmentClient.resetInquiry(investmentId, inquiryId)
  );
};

export const useEventDispatcher = () => {
  return useMutation(
    async ({ event, action, investmentId, actor, ...options }) =>
      InvestorInvestmentClient.dispatchEvent(investmentId, {
        event,
        action,
        actor,
        ...options,
      })
  );
};
