import { Box, Flex, IconButton, Link, Tooltip } from "@chakra-ui/react";
import { DownloadIconButton } from "@equidefi/portals/components/investments/DownloadButton";
import { Text } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";
import React, { useEffect, useMemo } from "react";

import { usePDF } from "@react-pdf/renderer";
import PaymentReceiptPDF from "./PaymentReceiptPDF";

const AgreementsItem = ({
  iconCondition,
  title,
  buttonAction,
  button = null,
  tooltip,
  isDisabled,
  isLoading,
}) => {
  const IconComponent = iconCondition ? Icon.CheckCircle : Icon.AlertTriangle;

  const Button = button ? (
    button
  ) : (
    <IconButton
      color="equidefi.blue"
      bg="none"
      icon={<Icon.Download size="1.5em" />}
      onClick={buttonAction}
      isDisabled={isDisabled}
      isLoading={isLoading}
    />
  );

  return (
    <Box
      w="full"
      py={2}
      borderBottom="1px"
      borderColor="gray.200"
      _last={{ border: "unset", pb: 0 }}
    >
      <Tooltip
        isDisabled={!tooltip}
        label={tooltip}
        aria-label={tooltip}
        hasArrow
      >
        <Flex align="center">
          <IconComponent size="1em" />
          <Text maxW="60%" mb="0" ml={2} mr="auto">
            {title}
          </Text>
          {Button}
        </Flex>
      </Tooltip>
    </Box>
  );
};

const OfferingAgreementsList = ({
  isLoading: spinner = false,
  investment,
  data,
  onPrintNda = () => {},
}) => {
  const { summary, offering_agreement, offering } = data ?? {};

  const paymentReceived =
    investment?.payments?.length || investment?.stripe_payments?.length;

  const ReceiptDocument = useMemo(() => {
    if (!investment || !offering) return null;

    return <PaymentReceiptPDF investment={investment} offering={offering} />;
  }, [investment, offering]);

  const [receipt, updateReceipt] = usePDF({ document: ReceiptDocument });

  useEffect(() => {
    if (ReceiptDocument) updateReceipt(ReceiptDocument);
  }, [ReceiptDocument, updateReceipt]);

  return (
    <>
      {offering_agreement?.name && (
        <AgreementsItem
          agreementSigned={investment?.investor?.name}
          title={offering_agreement?.name}
          buttonAction={onPrintNda}
          button={
            <DownloadIconButton
              investmentId={investment?.id}
              isDisabled={!summary?.isCountersigned}
            />
          }
        />
      )}

      <AgreementsItem
        key={"Payment Confirmation"}
        agreementSigned={paymentReceived}
        title={"Payment Confirmation"}
        button={
          <IconButton
            as={Link}
            color="equidefi.blue"
            _hover={{ color: "white", bg: "accent" }}
            bg="none"
            icon={<Icon.Download size="1.5em" />}
            href={receipt.url}
            download={`payment-receipt-${investment?.id}.pdf`}
            isDisabled={receipt.error}
            isLoading={spinner || receipt.loading}
          />
        }
        tooltip={
          !paymentReceived &&
          "A receipt for bank wire or check payment will be available once payment has been received."
        }
      />
    </>
  );
};

export default OfferingAgreementsList;
