import {
  Alert,
  Button,
  Flex,
  Tooltip,
  useToast,
  useToken,
} from "@chakra-ui/react";
import { useUpdateVault } from "@equidefi/portals/hooks/useAccreditation";
import {
  ACCREDITATION_OPTIONS,
  PRO_LETTER,
} from "@equidefi/shared/constants/accreditation";
import { Heading, Text } from "@equidefi/ui";
import RadioGroup from "@equidefi/ui/forms/RadioGroup";
import { Icon } from "@equidefi/ui/icon";
import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useWorkflowPosition from "../../../hooks/useWorkflowPosition";
import { WorkflowContainer } from "../WorkflowContainer";
import { WorkflowContext } from "../context";
import { UploadComponent } from "./UploadComponent";

const helperText = {
  PRO_LETTER: {
    text: "Recommended. Pro letter is a CPA, attorney, registered investment advisor, or broker-dealer letter on their letterhead, attesting to your status as an accredited investor based upon their knowledge of your income or your net worth. This letter must be dated within the prior 60 days.",
    header: "Accredited Investor Verification Letter",
  },
  INCOME_VERIFICATION: {
    text: "Income verification documents (such as IRS Forms 1040 or W-2) for us to review that evidence of your income for the prior two years.",
    header: "Income Verification",
  },
  ASSET_VERIFICATION: {
    text: "Asset verification documents (such as bank or brokerage statements dated within the past 60 days), which we will review. If you use this method we will request a credit report from a national credit reporting agency to verify your debt, obtain a letter from you verifying that no debt exists other than what appears on the credit report, and calculate your estimated net worth to assist us in making a determination.",
    header: "Asset Verification",
  },
};

export const WorkflowAccreditation = () => {
  const { investment, analytics } = useContext(WorkflowContext);
  const navigate = useNavigate();
  const [fileCount, setFileCount] = useState(1);
  const updateVault = useUpdateVault(investment.id);
  useWorkflowPosition(8);
  const toast = useToast();
  const [spinner, showSpinner] = useState(false);
  const equidefiBlue = useToken("colors", "equidefi.blue");

  const onSubmit = async () => {
    showSpinner(true);
    try {
      await updateVault.mutateAsync(values);

      try {
        analytics.track("complete");
        navigate(`/vault/dashboard`, {
          state: {
            completed: true,
            investment_id: investment.id,
          },
        });
      } catch (error) {
        console.log(error);
        toast({
          status: "error",
          description: "Failed to update your investment",
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        status: "error",
        description: "Failed to submit your files",
      });
    }
    showSpinner(false);
  };

  const { handleSubmit, values, handleChange, setFieldValue, touched, errors } =
    useFormik({
      initialValues: {
        accreditation_method: PRO_LETTER,
      },
      validateOnChange: false,
      validateOnBlur: false,
      onSubmit,
    });

  return (
    <WorkflowContainer
      title="Verify your Accreditation"
      onContinue={onSubmit}
      buttonLabel="Finish Investment"
      buttonProps={{
        isDisabled: !values.doc_1_file || !values.utility_bill,
        isLoading: spinner,
      }}
    >
      <form onSubmit={handleSubmit}>
        <Text>
          You must upload documents that validate your accreditation status.
        </Text>

        <Alert
          status="info"
          w="full"
          p="4"
          mt={10}
          mb={10}
          borderRadius="8px"
          border="1px solid"
          borderColor="#718096"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Text textStyle="body2" m={0}>
            If you are having trouble uploading or would like to email your
            accreditation documents at a later date, please email them to{" "}
            <a
              href="mailto:accreditation@equidefi.com"
              target="_blank"
              rel="noreferrer"
            >
              <b>
                <u>accreditation@equidefi.com</u>
              </b>
            </a>{" "}
            and we will be happy to upload them for you to your account for
            review.
          </Text>
        </Alert>

        <Heading fontWeight="bold">Select your verification method</Heading>
        <RadioGroup
          fieldName="accreditation_method"
          options={ACCREDITATION_OPTIONS}
          errors={touched.accreditation_method && errors}
          setFieldValue={setFieldValue}
          defaultValue={values.accreditation_method}
        />
        <Alert
          status="info"
          flexDirection="column"
          borderRadius="8"
          border="1px solid"
          borderColor="equidefi.blue"
          mb="8"
          alignItems="start"
          padding="4"
        >
          <Heading fontWeight="bold" m="0" textStyle="h3">
            {helperText[values.accreditation_method].header}
          </Heading>
          <Text m="0">{helperText[values.accreditation_method].text}</Text>
        </Alert>

        <Heading fontWeight="bold" m="0">
          Upload your accreditation documents
        </Heading>

        <Text mb="2">
          Select a file and click the upload button to submit your accreditation
          file.
        </Text>
        {Array.from({ length: fileCount }).map((_, index) => (
          <UploadComponent
            investmentId={investment.id}
            id={`document_upload_${index + 1}`}
            handleChange={handleChange}
            vaultField={`doc_${index + 1}_file`}
            fileNum={index + 1}
            key={index}
          />
        ))}
        <Text mb="2.5">
          Please upload the selected files by clicking the upload button next to
          each file.
          <Icon.Upload
            color={equidefiBlue}
            size="1em"
            style={{
              verticalAlign: "bottom",
              display: "inline",
              margin: "0 0 4 4",
            }}
          />
        </Text>
        {fileCount < 5 ? (
          <Button
            onClick={(e) => {
              e.preventDefault();
              setFileCount(fileCount + 1);
            }}
            variant="outline"
            mt="1"
            justifyContent="start"
            colorScheme="gray"
            borderColor="equidefi.darkGray"
            h="12"
          >
            + Add additional file
          </Button>
        ) : null}

        <Flex align="center" gap="1" mt="10">
          <Heading fontWeight="bold" m="0">
            Upload Proof of Address or Utility Bill
          </Heading>
          <Tooltip
            hasArrow
            label="This can be a
            utility bill (e.g., electric, gas, cable service, internet, etc.)
            which clearly shows your name, address and date."
          >
            <Icon.Info size="1em" />
          </Tooltip>
        </Flex>

        <Text>
          Select a file and click the upload button to submit your proof of
          address.
        </Text>

        <UploadComponent
          investmentId={investment.id}
          id="document_upload_utility"
          handleChange={handleChange}
          vaultField={`utility_bill`}
          mb={0}
        />
        <Text mb="2.5">
          Please upload the selected files by clicking the upload button next to
          each file.
          <Icon.Upload
            color={equidefiBlue}
            size="1em"
            style={{
              verticalAlign: "bottom",
              display: "inline",
              margin: "0 0 4 4",
            }}
          />
        </Text>

        <Text textStyle="context" my="2.5">
          We care about your Privacy.{" "}
          <Text
            as={Link}
            textDecor="underline"
            _hover={{ color: "gray.500" }}
            color="gray.400"
            fontSize="sm"
            target="_blank"
            rel="noreferrer"
            to="https://www.skyflow.com/post/what-is-a-data-privacy-vault"
          >
            {"Learn More >"}
          </Text>
        </Text>
      </form>
    </WorkflowContainer>
  );
};
